<template>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header modal-header_no-border">
                <h3 class="block-title">Редактирование дерево Базы знаний</h3>
                <button
                    type="button"
                    class="btn-block-option close-modal"
                    @click="$modal.hide('edit-tree-material')"
                >
                    ×
                </button>
            </div>

            <div class="block-content content">
                <div class="edit-tree">
                    <el-menu
                        class="edit-tree-menu"
                        @open="onEditTreeMenuOpen"
                    >
                        <nav-tree-material
                            class="edit-tree-menu__item"
                            mode="editing"
                            is-index="0"
                            :is-data="title"
                            :is-open="materialSubMenuOpen"
                        />
                    </el-menu>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import NavTreeMaterial from '@/components/knowledge_base/menu/NavTreeMaterial';

    export default {
        name: 'EditTreeMaterial',
        components: {
            NavTreeMaterial
        },

        data() {
            return {
                title: {
                    name: 'База знаний',
                    link: '/knowledge',
                    icon: 'knowledge.svg',
                    children: [],
                    soon: false
                },
                materialSubMenuOpen: false
            };
        },

        methods: {
            onEditTreeMenuOpen(key) {
                const menuKey = parseInt(key);

                if (menuKey === 0) this.materialSubMenuOpen = true;
            }
        }
    };
</script>

<style lang="scss">
    @import 'edit-tree-material';

    [data-modal="edit-tree-material"] {
        .v--modal {
            height: 90vh !important;
            overflow-y: auto;
        }

        .modal {
            &-dialog {
                margin-top: 0;
                max-width: none;
            }
        }
    }
</style>
